import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import axios from "axios";
import Sectiontitle from "../../components/Sectiontitle";
import { helmetJsonLdProp } from "react-schemaorg";
import Layout from "../../components/Layout";
import Spinner from "../../components/Spinner";

function ShowcaseDetails() {
  const params = useParams();
  const [metaData, setMetaData] = useState({});
  const [Component, setComponent] = useState(null);
  const showcaseId = params.title;

  useEffect(() => {
    // Fetch showcase metadata
    axios
      .get(`/api/showcase/${showcaseId}`)
      .then((response) => {
        setMetaData(response.data);
      })
      .catch((err) => console.error("Error fetching showcase metadata:", err));

    // Import the React component
    import(`../../components/${showcaseId}`)
      .then((module) => {
        setComponent(() => module.default);
      })
      .catch((err) => console.error("Error importing component:", err));
  }, [showcaseId]);

  if (!metaData.title || !Component) {
    return <Spinner />;
  }

  const metaTitle =
    metaData.title.length > 60
      ? `${metaData.title.substring(0, 60)}...`
      : metaData.title;

  return (
    <Layout>
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: metaData.title,
            description: metaData.description,
            datePublished: metaData.publishedAt,
            dateModified: metaData.updatedAt,
          }),
        ]}
      >
        <title>Component Showcase - {metaData.title}</title>
        <meta
          name="description"
          content={`Component Showcase - ${metaData.description}`}
        />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:image" content={metaData.featuredImage} />
        <meta
          property="og:url"
          content={`https://example.com/showcase/${metaData.id}`}
        />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image" content={metaData.featuredImage} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <Sectiontitle title={metaData.title} />
            <p>
              Created on:{" "}
              {`${metaData.createDay} ${metaData.createMonth} ${metaData.createYear}`}
            </p>
            <div className="component-preview">
              <Component />
            </div>
          </div>
        </div>
      </Suspense>
    </Layout>
  );
}

export default ShowcaseDetails;
