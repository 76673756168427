import { Link, NavLink } from "react-router-dom";
import { JsonLd } from "react-schemaorg";
import Image from "../components/Image";

function Person(props) {
  return (
    <div className="mi-header-image">
      <Link to={props.link} title={props.name}>
        <Image src={props.image} alt={props.name} />
      </Link>
      <JsonLd
        item={{
          "@context": "https://schema.org",
          "@type": "Person",
          name: props.name,
          image: props.image,
          alumniOf: {
            "@type": "CollegeOrUniversity",
            name: ["Bournemouth University", "University of Worcester"],
          },
          knowsAbout: [
            "React",
            "Node.js",
            "Express.js",
            "MongoDB",
            "GraphQL",
            "Next.js",
            "JAMstack",
            "PWA",
            "SPA",
            "SSR",
            "SEO",
            "SASS",
            "LESS",
          ],
          jobTitle: "Lead Frontend Developer",
        }}
      />
    </div>
  );
}

export default Person;
