import React, { useState } from "react";
import { Sun, Moon } from "lucide-react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import About from "./pages/About";
import Showcase from "./pages/showcase";
import ShowcaseDetails from "./pages/showcase/ShowcaseDetails";
import BlogDetails from "./pages/BlogDetails";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Notfound from "./pages/Notfound";
import Portfolio from "./pages/Portfolio";
import Resume from "./pages/Resume";
import ClientPreview from "./pages/ClientPreview";

function App() {
  const [lightMode, setLightMode] = useState(false); // Made it true if you want to load your site light mode primary

  lightMode
    ? document.body.classList.add("light")
    : document.body.classList.remove("light");

  const handleMode = () => {
    if (!lightMode) {
      setLightMode(true);
      document.body.classList.add("light");
    } else {
      setLightMode(false);
      document.body.classList.remove("light");
    }
  };

  return (
    <BrowserRouter>
      <div className="light-mode">
        <span className="icon">
          {lightMode ? (
            <Sun className="text-yellow-400" size={24} />
          ) : (
            <Moon className="text-indigo-400" size={24} />
          )}
        </span>

        <label className="theme-toggle">
          <input type="checkbox" checked={!lightMode} onChange={handleMode} />
          <span className="slider"></span>
        </label>
      </div>
      <Routes>
        <Route path="/" index element={<Home lightMode={lightMode} />} />
        <Route path="about" element={<About />} />
        <Route path="cv" element={<Resume />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:id/:title" element={<BlogDetails />} />
        <Route path="showcase" element={<Showcase />} />
        <Route path="showcase/:title" element={<ShowcaseDetails />} />
        <Route path="contact" element={<Contact />} />
        <Route path="client/:clientName" element={<ClientPreview />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
