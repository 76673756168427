import React from "react";
import { Link } from "react-router-dom";

import Image from "./Image";

function Showcase(props) {
  const { id, featuredImage, title, createDay, createMonth, createYear } =
    props.data;
  const getShortMonth = (month) => {
    return month.slice(0, 3);
  };
  return (
    <div className="mi-blog">
      <div className="mi-blog-image">
        <Link to={`${id}`}>
          {featuredImage && <Image src={featuredImage} alt={title} />}
        </Link>
        <div className="mi-blog-date">
          <span className="date">{createDay}</span>
          <span className="month">{getShortMonth(createMonth)}</span>
          <span className="year">{createYear}</span>
        </div>
      </div>
      <div className="mi-blog-content">
        <h5>
          <Link to={`${id}`}>{title}</Link>
        </h5>
      </div>
    </div>
  );
}

export default Showcase;
